import React, { useRef, useState } from "react";
import { submitInviteCode } from "../utils/dbhelpers";
import { navigate } from "@reach/router";
import firebase from "firebase/app";
import "firebase/auth";
import get from "lodash/get";
import "../styles/CreateAccount.css";

function CreateAccount(props) {
  const codeRef = useRef();
  const emailRef = useRef();
  const displayNameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const [sending, setSending] = useState(false);

  function onForumSubmit(e) {
    e.preventDefault();
    setErrorMessage(null);
    const password = get(passwordRef, "current.value");
    const confirmPassword = get(confirmPasswordRef, "current.value");
    const email = get(emailRef, "current.value");
    if (!password) {
      setErrorMessage("Password cannot be blank.");
      return;
    }
    if (!email) {
      setErrorMessage("Email cannot be blank.");
      return;
    }
    if (password.length < 8) {
      setErrorMessage("Password should be at least 8 characters.");
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage("Password fields don't match.");
      return;
    }
    const userData = {
      email,
      displayName: get(displayNameRef, "current.value"),
      password
    };
    setSending(true);
    submitInviteCode(get(codeRef, "current.value"), userData, true)
      .then(result => {
        if (get(result, "data.error")) {
          throw new Error(result.data.error);
        } else {
          return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => navigate("/"));
        }
      })
      .catch(e => setErrorMessage(e.message))
      .finally(() => setSending(false));
  }

  function fill() {
    passwordRef.current.value = "password";
    confirmPasswordRef.current.value = "password";
    emailRef.current.value = "test@testing.com";
    displayNameRef.current.value = "Test Name";
  }

  // if (props.user && props.claims.validated) {
  //   return (
  //     <div className="create-account-container">
  //       You already have an account!
  //     </div>
  //   );
  // }

  return (
    <div className="create-account-container">
      <form className="signup-form" onSubmit={onForumSubmit}>
        <div className="form-row">
          <label>email</label>
          <input ref={emailRef} type="email" placeholder="email address" />
        </div>
        <div className="form-row">
          <label>display name</label>
          <input ref={displayNameRef} placeholder="name to show on posts" />
        </div>
        <div className="form-row">
          <label>password</label>
          <input ref={passwordRef} type="password" />
        </div>
        <div className="form-row">
          <label>confirm password</label>
          <input ref={confirmPasswordRef} type="password" />
        </div>
        <div className="form-row">
          <label>code</label>
          <input ref={codeRef} defaultValue={props.code || ""} />
        </div>
        <div className="button-container">
          <button disabled={sending} className="button-edit">
            create my account
          </button>
        </div>
        {sending && <div className="loader" />}
      </form>
      {window.location.hostname === "localhost" && (
        <button className="button-danger" onClick={fill}>
          fill with test data
        </button>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default CreateAccount;
